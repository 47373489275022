




































































































































































































































































































































































































































































.raise-rss-notifications.has-notification{
  padding: 3px;
  background-color: #daaf45;
}

.rss-summary-table .error-cell{
  background-color: #fac8c8;
}
